.article-container {
  font-size: 16px;
  line-height: 150%;
  color: #2e2e2e;
  font-weight: 400;
}
.article-container p {
  margin-top: 20px;
}
.article-container p.blue-line {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  padding-left: 32px;
  position: relative;
  margin: 40px 0 20px;
}
.article-container p.blue-line:before {
  content: '';
  position: absolute;
  top: 11px;
  left: 0;
  width: 20px;
  height: 4px;
  background: #3570c9;
}
.article-container a {
  text-decoration: underline;
  color: #3867d6;
  font-weight: 500;
}
.article-container a:hover {
  color: #3c6cff;
  transition: 0.45s ease-out;
}
.article-container ul {
  margin-top: 30px;
}
.article-container ul li {
  font-size: 16px;
  line-height: 150%;
  color: #2e2e2e;
  position: relative;
}
.article-container .check-list li {
  padding-left: 44px;
  margin-bottom: 30px;
}
.article-container .check-list li:last-child {
  margin-bottom: 0;
}
.article-container .check-list li:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 28px;
  height: 28px;
  display: block;
  transform: translateY(-50%);
  background-image: url('/img/articles/checkBox.svg');
  background-size: 28px 28px;
}
.article-container .square-list.square-list-col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
}
.article-container .square-list li {
  padding-left: 30px;
  margin-bottom: 18px;
}
.article-container .square-list li:last-child {
  margin-bottom: 0;
}
.article-container .square-list li:before {
  content: '';
  position: absolute;
  top: 4px;
  left: 0;
  width: 15px;
  height: 15px;
  background: #3570c9;
  border: 4px solid #fff;
  outline: 2px solid #3570c9;
}
.article-container .list-with li {
  padding-left: 44px;
  margin-bottom: 20px;
  position: relative;
}
.article-container .list-with li:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 28px;
  height: 28px;
  border: 1px solid #dde4ea;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  line-height: 28px;
  color: #3570c9;
}
.article-container .list-with li strong,
.article-container .list-with li h3 {
  display: block;
  font-size: 16px;
  line-height: 150%;
  font-weight: 600;
  text-align: left;
  margin-bottom: 10px;
}
.article-container .list-with.plus li:before {
  content: '+';
}
.article-container .list-with.minus li:before {
  content: '';
}
.article-container .list-with.minus li:after {
  content: '';
  position: absolute;
  top: 13px;
  left: 7px;
  width: 14px;
  height: 2px;
  background: #3570c9;
}
.article-container .list-with.question li:before {
  content: '?';
  font-size: 18px;
}
.article-container .number-list {
  padding-left: 70px;
  position: relative;
  margin-bottom: 30px;
}
.article-container .number-list:after {
  content: '';
  position: absolute;
  top: 0;
  left: 20px;
  width: 0;
  height: 92%;
  border-left: 1px dashed #a5b3c5;
}
.article-container .number-list li {
  position: relative;
  margin-bottom: 50px;
  min-height: 40px;
  counter-increment: li;
}
.article-container .number-list li::before {
  content: counter(li);
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  color: #3570c9;
  border: 1px solid #3570c9;
  background: #fff;
  position: absolute;
  z-index: 2;
  top: -2px;
  left: -70px;
  width: 40px;
  height: 40px;
}
.article-container .number-list li:last-child {
  margin-bottom: 0;
}
.article-container .bg-block {
  background: #f5f7fa;
  padding: 30px;
  position: relative;
  margin-top: 20px;
}
.article-container .bg-block.bg-block-quote {
  font-size: 14px;
  line-height: 170%;
  font-style: italic;
  color: #2e2e2e;
  background: #f5f7fa;
  padding: 30px 80px;
  position: relative;
}
.article-container .bg-block.bg-block-quote:before,
.article-container .bg-block.bg-block-quote:after {
  content: '“';
  position: absolute;
  top: 24px;
  left: 0;
  width: 45px;
  height: 36px;
  color: #dde4ea;
  opacity: 0.4;
  font-size: 114px;
  line-height: 86%;
}
.article-container .bg-block.bg-block-quote:after {
  top: auto;
  bottom: 24px;
  right: 0;
  left: auto;
  transform: rotate(180deg);
}
.article-container .bg-block p {
  margin-bottom: 20px;
}
.article-container .bg-block p:last-child {
  margin-bottom: 0;
}
.article-container .info-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  margin-top: 16px;
}
.article-container .info-box.info-box-less {
  grid-template-columns: 270px 1fr;
}
.article-container .info-box img {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 260px;
  object-fit: cover;
  object-position: center;
}
.article-container .accordion {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 8px;
}
.article-container .accordion details {
  background: #f5f7fa;
}
.article-container .accordion details[open] summary:after {
  transform: translateY(-50%) rotate(180deg);
}
.article-container .accordion details summary {
  padding: 24px 72px 24px 24px;
  list-style: none;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.article-container .accordion details summary:after {
  transition: 0.2s linear;
  content: url("data:image/svg+xml; utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' fill='blue'><path d='M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z' /></svg>");
  height: 25px;
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
}
.article-container .accordion details summary::-webkit-details-marker {
  display: none;
}
.article-container .accordion details p {
  margin: 0 24px 24px 24px;
  padding-top: 24px;
  border-top: 1px solid #dde4ea;
}
.article-container h2 {
  font-weight: 400;
  font-size: 32px;
  line-height: 39px;
  margin-top: 40px;
  color: #2e2e2e;
}
.article-container h3,
.article-container h4 {
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  margin-top: 40px;
}
.article-container h3 {
  margin-top: 40px;
}
.article-container img {
  width: 100%;
  height: 340px;
  display: block;
  object-fit: cover;
  object-position: center;
  margin-top: 30px;
}
.article-container s {
  text-decoration: none;
  display: block;
  padding-top: 24px;
}
.article-container s:first-child {
  padding-top: 0;
}
.article-container .table {
  font-size: 16px;
  line-height: 20px;
  color: #2e2e2e;
  text-align: center;
  width: 100%;
  border: 1px solid #dde4ea;
  table-layout: fixed;
  margin-top: 24px;
}
.article-container .table thead {
  background: #f5f7fa;
}
.article-container .table thead th {
  padding: 18px 10px;
  font-weight: 600;
  text-align: left;
  border-left: 1px solid #dde4ea;
}
.article-container .table thead th:first-child {
  border-left: none;
}
.article-container .table thead th span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: block;
}
.article-container .table tr {
  border-bottom: 1px solid #dde4ea;
}
.article-container .table td {
  border-left: 1px solid #dde4ea;
  padding: 8px 10px;
  text-align: left;
}
.article-container .table td:first-child {
  border-left: none;
}
.article-container .table.column-left-text tr td:first-child {
  text-align: left;
  padding-left: 24px;
}

@media (max-width: 1024px) {
  .article-container .square-list.square-list-col {
    grid-template-columns: 1fr;
  }
  .article-container .info-box {
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
  }
  .article-container .info-box img {
    height: 340px;
    max-height: 100%;
  }
  .article-container h2 {
    font-size: 24px;
    line-height: 29px;
  }
  .article-container h2 strong {
    display: block;
  }
  .article-container h3,
  .article-container h4 {
    font-size: 18px;
  }
  .article-container img {
    height: 270px;
  }
  .article-container .table {
    font-size: 14px;
  }
  .article-container .table thead th {
    padding-left: 6px;
    padding-right: 6px;
  }
}

@media (max-width: 960px) {
  .article-container .bg-block {
    padding: 30px 25px;
  }
  .article-container .bg-block.bg-block-quote {
    padding: 30px 60px;
  }
  .article-container .bg-block.bg-block-quote:before,
  .article-container .bg-block.bg-block-quote:after {
    left: 12px;
  }
  .article-container .bg-block.bg-block-quote:after {
    right: 12px;
  }
  .article-container .info-box img {
    height: 300px;
    max-height: 100%;
  }
}
@media (max-width: 768px) {
  .article-container .bg-block {
    padding: 30px;
  }
  .article-container .bg-block.bg-block-quote {
    padding: 51px 40px;
  }
  .article-container .bg-block.bg-block-quote:before,
  .article-container .bg-block.bg-block-quote:after {
    top: 12px;
  }
  .article-container .bg-block.bg-block-quote:after {
    bottom: 12px;
  }
  .article-container .info-box img {
    height: auto;
    object-fit: contain;
    object-position: right;
  }
  .article-container .table.column-left-text tr td:first-child {
    padding-left: 12px;
  }
}

@media (max-width: 420px) {
  .article-container {
    font-size: 14px;
  }
  .article-container p {
    font-size: 16px;
  }
  .article-container ul li {
    font-size: 14px;
  }
  .article-container .number-list {
    padding-left: 60px;
  }
  .article-container .number-list li {
    margin-bottom: 30px;
  }
  .article-container .number-list li::before {
    left: -60px;
  }
  .article-container .info-box.info-box-less {
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
  }
  .article-container h2 {
    font-size: 20px;
    line-height: 24px;
    margin-top: 30px;
  }
  .article-container img {
    height: 190px;
  }
  .article-container .table thead {
    font-size: 10px;
    line-height: 150%;
  }
  .article-container .table thead th {
    padding-left: 2px;
    padding-right: 2px;
  }
  .article-container .table thead th span {
    font-size: 10px;
    line-height: 150%;
  }
  .article-container .table td {
    padding: 6px;
    font-size: 10px;
    line-height: 150%;
  }
  .article-container .table.column-left-text tr td:first-child {
    font-size: 10px;
  }
}
@media (max-width: 360px) {
  .article-container .bg-block {
    padding: 20px;
  }
  .article-container .bg-block.bg-block-quote {
    padding: 51px 20px;
  }
  .article-container .info-box {
    grid-row-gap: 20px;
  }
  .article-container h3,
  .article-container h4 {
    font-size: 16px;
  }
}
@media (max-width: 340px) {
  .article-container .number-list {
    padding-left: 50px;
  }
  .article-container .number-list li::before {
    left: -50px;
  }
  .article-container .bg-block {
    padding: 20px 14px 30px 20px;
  }
  .article-container .bg-block.bg-block-quote {
    padding: 51px 15px;
  }
}
