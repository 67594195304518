.ant-modal-mask {
  background-color: rgb(22, 31, 40, 0.67);
}
.quill {
  background: #f5f7fa;
}
.ql-toolbar.ql-snow {
  border: 1px solid #dde4ea;
}
.ql-container.ql-snow {
  border: 1px solid #dde4ea;
  overflow-y: auto;
  max-height: 500px;
}
.ql-formats button i {
  display: block;
}
/* test span block */

.ql-editor {
  display: block;
}
.raw-editor {
  display: none;
  width: 100%;
}
.showRaw .ql-editor {
  display: none;
}
.showRaw .raw-editor {
  display: block;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  z-index: 2;
}


input {
  border: none;
  outline: none;

  ::placeholder {
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    color: grey;
  }
}

a {
  text-decoration: none;
  outline: none;
}

ol,
ul {
  list-style: none;
}

html {
  -ms-overflow-style: scrollbar;
}

body {
  font-family: $font;
  font-size: 16px;
  min-width: 320px;
  min-height: 100vh;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow-x: hidden;

  p,
  a,
  span,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    color: unset;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  * {
    -webkit-tap-highlight-color: transparent;
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
    &::before,
    &::after {
      -webkit-box-sizing: inherit;
      -moz-box-sizing: inherit;
      box-sizing: inherit;
    }
  }
}

::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
:-moz-placeholder {
  color: #a1a0a0;
  font-family: $font;
  font-weight: normal;
}


#__next {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
}

h2 {
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  color: $black;
  @media #{$md} {
    font-size: 32px;
    line-height: 39px;
  }
  @media #{$sm} {
    font-size: 24px;
    line-height: 29px;
  }
  @media #{$xs} {
    font-size: 24px;
    line-height: 29px;
  }
}

.main{
  display: grid;
  justify-content: center;
  flex: 1 0 auto;
  grid-row-gap: 120px;
  @media #{$md} {
    grid-row-gap: 70px;
  }
  @media #{$xs} {
    grid-row-gap: 60px;
  }

  & > section {
    width: 100vw;
    // max-width: 1920px;
    //content-visibility: auto;
  }
}



.treeDd {
  font-family: $font;
  color: #2e2e2e;
  .ant-select-tree-list {
    ::-webkit-scrollbar-track {
      background-color: #dde4ea;
    }

    ::-webkit-scrollbar {
      width: 3px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: #a5b3c5;
      border-radius: 5px;
    }
  }
}

.selectDd {
  font-family: $font;
  color: #2e2e2e;

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #f5f7fa;
  }

  .ant-select-item .ant-select-item-option .ant-select-item-option-active {
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
  }
}
.DDHome {
  &::-webkit-scrollbar-track {
    background-color: #dde4ea;
  }

  &::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #a5b3c5;
    border-radius: 5px;
  }
}


.dropdownCompanyFields {
  .ant-select-item-option-disabled {
    .ant-select-item-option-content div {
      color: #bfbfbf;
    }
  }
}

.dropdownCustomStyle {
  .ant-select-item {
    min-height: auto;
    padding: 10px 12px;
    @media #{$sm} {
      padding: 10px 6px;
    }
  }
  .ant-select-item-option-content {
    font-family: $font;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    color: $black;
    @media #{$sm} {
      font-size: 10px;
    }
  }
}

.dropdownCustomOptionBox {
  background: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  padding-top: 10px;
  padding-bottom: 10px;

  .ant-select-item-option {
    padding: 0 26px;
    &:last-child {
      div {
        border-bottom: none;
      }
    }
  }
  .ant-select-item-option-content {
    white-space: normal;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    color: #3570c9;
    padding-top: 10px;
  }
}

.customDropdownDatePicker {
  .ant-picker-decade-panel,
  .ant-picker-year-panel,
  .ant-picker-quarter-panel,
  .ant-picker-month-panel,
  .ant-picker-week-panel,
  .ant-picker-date-panel,
  .ant-picker-time-panel {
    width: 385px;
    @media #{$md} {
      width: 340px;
    }
    @media #{$sm} {
      width: 280px;
    }
  }
  .ant-picker-range-wrapper.ant-picker-date-range-wrapper {
    min-width: 385px !important;
    max-width: 385px;
    @media #{$md} {
      min-width: 340px !important;
      max-width: 340px;
    }
    @media #{$sm} {
      min-width: 280px !important;
      max-width: 280px;
    }
  }
  .ant-picker-panel-container .ant-picker-panel:last-child {
    display: none;
  }
  .ant-picker-header-super-prev-btn,
  .ant-picker-header-super-next-btn,
  &.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
    display: none;
  }
  .ant-picker-header-next-btn {
    visibility: visible !important;
  }
  .ant-picker-panel-container {
    background: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  }
  .ant-picker-header {
    border-bottom: none;
  }
  .ant-picker-header button {
    color: #a5b3c5;
  }
  .ant-picker-header-view button {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #2e2e2e;
  }

  .ant-picker-date-panel .ant-picker-body {
    padding: 0 0 8px 0;
  }
  .ant-picker-date-panel .ant-picker-content {
    width: 100%;
  }
  .ant-picker-content th {
    font-size: 14px;
    line-height: 20px;
    color: #2e2e2e;
  }
  .ant-picker-content tbody {
    tr:last-child {
      display: none;
    }
  }
  .ant-picker-cell .ant-picker-cell-inner {
    font-size: 14px;
    line-height: 40px;
    color: #a5b3c5;
    width: 54px;
    height: 40px;
    text-align: center;
    @media #{$md} {
      width: 50px;
    }
    @media #{$sm} {
      width: 40px;
      height: 36px;
      line-height: 36px;
    }
  }
  .ant-picker-cell-in-view .ant-picker-cell-inner {
    color: #2e2e2e;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today {
    .ant-picker-cell-inner {
      color: #3570c9;
      font-weight: 600;
      &::before {
        top: auto;
        left: 50%;
        bottom: 4px;
        transform: translateX(-50%);
        width: 4px;
        height: 4px;
        background: #3570c9;
        border: none;
      }
    }
  }
  .ant-picker-cell::before {
    height: 40px;
    @media #{$sm} {
      height: 36px;
    }
  }
  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
    .ant-picker-cell-inner {
    background: #f5f7fa;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: #3570c9;
    color: #fff;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end)
    .ant-picker-cell-inner {
    border-radius: 0;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: #f5f7fa;
  }
}

.SubscriptionTooltip {
  max-width: 315px;
}
.tooltipCustomUi {
  transition: transform 0.3s ease-out;
  width: 315px;
  max-width: 315px;
  @media #{$sm} {
    max-width: 280px;
    width: 280px;
  }
  .ant-tooltip-arrow {
    width: 14px;
  }
  .ant-tooltip-arrow-content {
    width: 10px;
    height: 10px;
    background: #2e2e2e;
  }
  .ant-tooltip-inner {
    background: #2e2e2e;
    padding: 14px 16px 16px 16px;
  }
}

.ant-empty .ant-empty-description {
  display: none;
}

.wrap-content {
  width: 1410px;
  margin: 0 auto;

  @media (max-width: 1600px) {
    width: 1306px;
  }

  @media (max-width: 1386px) {
    width: 1170px;
  }

  @media (max-width: 1230px) {
    width: 100%;
    padding: 10px 30px;
  }

  @media (max-width: 480px) {
    padding: 5px 20px;
  }
  @media (max-width: 340px) {
    padding: 5px 10px;
  }
}


.link-icon{
  position: relative;
  display: inline-flex;
  &:hover{
    color: inherit;
    &:after{
      content: "";
      width: 32px;
      height: 32px;
      border-radius: 2px;
      display: flex;
      margin-left: 22px;
      background: #3570C9 url(/img/arrow/arrow-right.svg) no-repeat center;
      background-size: 18px;
      align-self: center;
    }
  }
}

button{
  color: #ffffff;
  border-color: #3570c9;
  background: #3570c9;
}

.timer{
  display: none;
  &.on{
    display: block;
  }
}
