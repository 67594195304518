@media (prefers-color-scheme: dark) {
  body {
    background-color: #ffffff;
  }
}

.loading-wrappger {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-content: center;
  background: rgb(255, 255, 255);
}

//for video player (poster)
.video-react .video-react-poster {
  background: rgb(0, 0, 0, 0.6) !important;
}

.centered {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

.ml-auto,
.mx-auto {
  margin-left: auto;
}
.mr-auto,
.mx-auto {
  margin-right: auto;
}
.pl-0 {
  padding-left: 0;
}
.pr-0 {
  padding-right: 0;
}
.ml-0 {
  margin-left: 0;
}
.mr-0 {
  margin-right: 0;
}
.w-100 {
  width: 100%;
}
.f-nowrap {
  flex-wrap: nowrap;
}

.hidden {
  display: none;
}
.right {
  float: right;
}
.left {
  float: left;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.rel {
  position: relative;
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  max-width: 100%;
}

.body-overflow {
  width: 100%;
  position: fixed;
  padding-right: 15px;
}

.no-wrap {
  white-space: nowrap;
}

.no-empty {
  &:empty {
    display: none;
  }
}

// ------ positions

.f- {
  &top {
    &-left,
    &-center,
    &-right,
    &-space-around,
    &-space-between {
      display: flex;
      align-items: flex-start;
    }
    &-left {
      justify-content: flex-start;
    }
    &-center {
      justify-content: center;
    }
    &-right {
      justify-content: flex-end;
    }
    &-space-around {
      justify-content: space-around;
    }
    &-space-between {
      justify-content: space-between;
    }
    &-stretch {
      justify-content: stretch;
    }
  }

  &center {
    &-center,
    &-left,
    &-right,
    &-space-around,
    &-space-between {
      display: flex;
      align-items: center;
    }
    &-center {
      justify-content: center;
    }
    &-left {
      justify-content: flex-start;
    }
    &-right {
      justify-content: flex-end;
    }
    &-space-around {
      justify-content: space-around;
    }
    &-space-between {
      justify-content: space-between;
    }
    &-stretch {
      justify-content: stretch;
    }
  }

  &bottom {
    &-left,
    &-center,
    &-right,
    &-space-around,
    &-space-between {
      display: flex;
      align-items: flex-end;
    }
    &-left {
      justify-content: flex-start;
    }
    &-center {
      justify-content: center;
    }
    &-right {
      justify-content: flex-end;
    }
    &-space-around {
      justify-content: space-around;
    }
    &-space-between {
      justify-content: space-between;
    }
    &-stretch {
      justify-content: stretch;
    }
  }

  &space {
    &-around-left,
    &-around-center,
    &-around-right,
    &-around-space-around,
    &-around-space-between {
      display: flex;
      align-items: space-around;
    }
    &-between-left,
    &-between-center,
    &-between-right,
    &-between-space-around,
    &-between-space-between {
      display: flex;
      align-items: space-between;
    }
    &-around-left {
      justify-content: flex-start;
    }
    &-around-center {
      justify-content: center;
    }
    &-around-right {
      justify-content: flex-end;
    }
    &-around-space-around {
      justify-content: space-around;
    }
    &-around-space-between {
      justify-content: space-between;
    }
    &-between-left {
      justify-content: flex-start;
    }
    &-between-center {
      justify-content: center;
    }
    &-between-right {
      justify-content: flex-end;
    }
    &-between-space-around {
      justify-content: space-around;
    }
    &-between-space-between {
      justify-content: space-between;
    }
  }
  &-no-shrink {
    flex-shrink: 0;
  }
  &-no-grow {
    flex-grow: 0;
  }
}

@for $i from 1 through 12 {
  .mb-#{$i} {
    margin-bottom: $i * $grid-bottom;
  }
  .mt-#{$i} {
    margin-top: $i * $grid-bottom;
  }
}

.overflow{
  overflow: hidden;
}
