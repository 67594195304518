
// form elements antdesign

.form-input {
  position: relative;
  background: #FFFFFF;
  border: 1px solid $cUIdark;
  border-radius: 1px;
  box-sizing: border-box;
  box-shadow: none;
  height: 40px;
  padding: 4px 12px;
  input{
    font-size: 14px;
  }

  .ant-input-prefix {
    margin-right: 8px;
  }

  .anticon svg {
    fill: #8293AA;
    width: 16px;
    height: 16px;
  }

  input {
    background: #FFFFFF;
  }

  &:hover {
    border-color: #A5B3C5;
    .anticon svg {
      fill: #A5B3C5;
    }
  }
  &:focus,
  &.ant-input-affix-wrapper-focused{
    box-shadow: none;
    border-color: #3570C9;
    .anticon svg {
      fill: $cBrandPrimary;
    }
  }
  // custom props
  &._fill {
    background: #F5F7FA;
    input {
      background: #F5F7FA;
    }
  }
  &._large{
    height: 48px;
  }
  &._small{
    height: 32px;
  }
}


.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-select-tree-checkbox .ant-select-tree-checkbox-inner {
  border-color: #a5b3c5;
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #3570c9;
  border-color: #3570c9;
}
.ant-select-tree-treenode-checkbox-checked {
  .ant-select-tree-title {
    font-weight: 600;
  }
}
.ant-select-tree-title {
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
}
.ant-select-tree-treenode {
  .ant-select-tree-node-content-wrapper-open .ant-select-tree-title,
  .ant-select-tree-node-content-wrapper-close .ant-select-tree-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
  }
}
.ant-select-tree-switcher-icon svg {
  fill: #a5b3c5;
}
.ant-select-tree-treenode-checkbox-checked .ant-select-tree-switcher-icon svg {
  fill: #3570c9;
}
.ant-select-tree-treenode-checkbox-indeterminate {
  .ant-select-tree-switcher-icon svg {
    fill: #3570c9;
  }
  .ant-select-tree-title {
    color: #3570c9;
  }
  .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
    &:after {
      display: none;
    }
  }
}
.ant-checkbox {
  display: inline-block;
}

