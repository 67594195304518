
        @import "src/assets/styles/_variables.scss";
        $url: "https://static.iworld.com";
      
@import '_variables.scss';
@import '_reset.scss';
@import '_fonts.scss';
@import '~antd/dist/antd.min.css';
@import '_global.scss';
@import '_grid.scss';
@import '_common.scss';
@import '_forms.scss';
@import 'styleArtical.scss';
