// grid
@use 'sass:math';
@import './_variables.scss';

$grid-count: 12;

//$grid-xl-padding: 8 * $grid-bottom;
//$grid-md-padding: 6 * $grid-bottom;
//$grid-sm-padding: 4 * $grid-bottom;

$col-name: col;

@mixin col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  min-width: 0;
}

// ****** for css ******************

.container {
  width: 100%;
  padding-left: $grid-xl-padding + math.div(-$grid-md-padding, 2);
  padding-right: $grid-xl-padding + math.div(-$grid-md-padding, 2);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  box-sizing: border-box;
  @media #{$lg} {
    max-width: 1168px;
  }
  @media #{$md} {
    padding-left: $grid-xl-padding + math.div(-$grid-md-padding, 2);
    padding-right: $grid-xl-padding + math.div(-$grid-md-padding, 2);
  }
  @media #{$sm} {
    max-width: 96vw;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: math.div(-$grid-xl-padding, 2);
  margin-right: math.div(-$grid-xl-padding, 2);
  @media #{$md} {
    margin-left: math.div(-$grid-md-padding, 2);
    margin-right: math.div(-$grid-md-padding, 2);
  }
  @media #{$sm} {
    margin-left: math.div(-$grid-sm-padding, 2);
    margin-right: math.div(-$grid-sm-padding, 2);
  }
}

[class*='#{$col-name}-'],
.#{$col-name} {
  flex: none;
  min-height: 1px;
  flex-grow: 0;
  flex-shrink: 1;
  padding-left: math.div($grid-xl-padding, 2);
  padding-right: math.div($grid-xl-padding, 2);
  @media #{$md} {
    padding-left: math.div($grid-md-padding, 2);
    padding-right: math.div($grid-md-padding, 2);
  }
  @media #{$sm} {
    padding-left: math.div($grid-sm-padding, 2);
    padding-right: math.div($grid-sm-padding, 2);
  }
}

.#{$col-name} {
  @include col;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

//  grid ----------------------

@mixin make-col($name) {
  @for $i from 1 through $grid-count {
    .#{$name}-#{$i} {
      min-width: $i * math.div(100%, $grid-count);
      max-width: $i * math.div(100%, $grid-count);
      flex-basis: $i * math.div(100%, $grid-count);
    }
  }
}

@include make-col($col-name);

// Media

@mixin make-grid($size) {
  .#{$size}-hide {
    display: none !important;
  }
  .#{$size}-show {
    display: flex !important;
    flex-direction: column;
  }
  .#{$size}-show_b {
    display: block !important;
  }
  .#{$col-name}-#{$size}-auto {
    flex: 0 0 auto;
    width: auto;
    min-width: 0;
    max-width: 100%;
  }
  .#{$col-name}-#{$size} {
    @include col;
  }
}

@media #{$xl} {
  @include make-grid(xl);
  @include make-col(col-xl);
}
@media #{$lg} {
  @include make-grid(lg);
  @include make-col(col-lg);
}
@media #{$md} {
  @include make-grid(md);
  @include make-col(col-md);
}
@media #{$mds} {
  @include make-grid(mds);
  @include make-col(col-mds);
}
@media #{$sm} {
  @include make-grid(sm);
  @include make-col(col-sm);
}
@media #{$xs} {
  @include make-grid(xs);
  @include make-col(col-xs);
}
